import { useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import MainLayout from "../components/layout";
import FoundingSource from "../pages/fundingSource";
import Rate from "../pages/rate";
import Thankyou from "../pages/thankyou";
import FacialRecognition from "../pages/facial-recognition";
import { useLocation } from "react-router-dom";

const Router = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <Routes>
      <Route path="/" element={<MainLayout />}>
        <Route index element={<FoundingSource />} />
        <Route path="/rate" element={<Rate />} />
        <Route path="/thankyou" element={<Thankyou />} />
      </Route>
      <Route path="/facial-recognition" element={<FacialRecognition />} />
    </Routes>
  );
};
export default Router;
