import Ratee from "../../assets/images/ratee.png"
import { useNavigate } from "react-router"
const Thankyou = () => {
    const navigate = useNavigate()
    return (
        <div className="bg-white p-10 rounded-lg shadow-lg max-w-xl w-full">
            <center> <img src={Ratee} alt="rate" className="mx-auto mb-6" /> </center>
            <div className="mb-4 mx-auto text-center">
                <h1 className="font-semibold text-xl mb-2 mx-auto text-center">Thank you!</h1>

                <div className="text-center mx-auto mt-4 mb-3">
                    <span className="font-xl pb-10"> Please wait 1-3 Business days </span>
                </div>
            </div>
            <form className="text-center">
                <button onClick={()=>navigate('/')} className="cursor-pointer bg-gray-700 hover:bg-gray-700 text-white font-bold py-4 px-8 rounded-full mx-auto" id="link-button">Finish</button>
            </form>
        </div>
    )
}
export default Thankyou