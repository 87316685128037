import thunder1 from "../../assets/images/thunder1.svg"
import passwordx2 from "../../assets/images/passwordx2.svg"
import correctx1 from "../../assets/images/correctx1.svg"
interface IProps {
    openPlaid: any
}
const Stepper1 = ({openPlaid}:IProps) => {   
    return (
        <>
            <div className="bg-white p-10 rounded-lg shadow-lg max-w-xl w-full">
                <div className="mb-10 mx-auto text-center">
                    <h1 className="text-4xl font-bold text-gray-800 mb-4 mx-auto text-center">Funding Source</h1>
                    <p className="text-md text-gray-600 mb-2 mx-auto text-center">Login to your bank account for Instant Bank Verification (IBV)</p>
                </div>
                <div className="mx-auto grid grid-cols-1 gap-x-6 gap-y-6 px-4 lg:max-w-none lg:gap-y-16 mt-4">
                    <div className="sm:flex">
                        <div className="sm:flex-shrink-0">
                            <div className="flow-root">
                                <img className="h-24 w-28" src={thunder1} alt="" />
                            </div>
                        </div>
                        <div className="mt-3 sm:ml-3 sm:mt-0">
                            <h3 className="text-xl text-gray-600 font-bold mb-2">Fast</h3>
                            <p className="mt-2 text-sm text-gray-500"> IBV allows lightning fast verification by directly accessing your bank transaction data, avoiding delays from paperwork processing.</p>
                        </div>
                    </div>
                    <div className="sm:flex">
                        <div className="sm:flex-shrink-0">
                            <div className="flow-root">
                                <img className="h-24 w-28" src={passwordx2} alt="" />
                            </div>
                        </div>
                        <div className="mt-3 sm:ml-3 sm:mt-0">
                            <h3 className="text-xl text-gray-600 font-bold mb-2">Secure</h3>
                            <p className="mt-2 text-sm text-gray-500">You can rest assured your personal details are held private, as IBV uses military-grade encryption and authentication ensuring your bank login and data are securely accessed to maintain privacy and prevent fraud.</p>
                        </div>
                    </div>
                    <div className="sm:flex">
                        <div className="sm:flex-shrink-0">
                            <div className="flow-root">
                                <img className="h-24 w-28" src={correctx1} alt="" />
                            </div>
                        </div>
                        <div className="mt-3 sm:ml-3 sm:mt-0">
                            <h3 className="text-xl text-gray-600 font-bold mb-2">Convenient</h3>
                            <p className="mt-2 text-sm text-gray-500">IBV provides a simple, hassle-free process for customers to verify their identity and income online without tracking down physical documents.</p>
                        </div>
                    </div>
                </div>

                <br />
                <br />


                <form>
                    <center><button type="button" className="bg-gray-700 hover:bg-gray-700 text-white font-bold py-4 px-8 rounded-full mx-auto" id="link-button" onClick={()=>openPlaid()}>Link Bank Account</button></center>

                </form>
            </div>
            <div className="text-center mx-auto mt-4">
                <a href="#" className="group inline-flex text-base font-medium">
                    <svg className="mr-2 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75m-3-7.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285z"></path>
                    </svg>
                    <span className="text-gray-500 text-sm group-hover:text-gray-700">Fast &amp; Secure Transfer</span>
                </a>
            </div>
        </>
    )
}
export default Stepper1