import { useState} from 'react';
import Stepper1 from '../../components/fundingSource/stepper1';
import Stepper2 from '../../components/fundingSource/stepper2';
import { usePlaidLink } from 'react-plaid-link';
import axios from "axios";
const FoundingSource = () => {
    const [stepper, setStepper] = useState(1)
    const [metadataPayload, setMetaDataPayload] = useState<any>(null)
    const { open, ready } = usePlaidLink({
        clientName: 'Your App Name',
        env: 'sandbox',
        product: ['auth','transactions'],
        publicKey: '8e0fa9935f0397d506830f6970ac40',
        onSuccess: async (public_token, metadata) => {
            const data = {'token': public_token, 'details': metadata}
            await axios.post(process.env.REACT_APP_API_URL +'bank-details', data).then((response) => {
                console.log(response);
                setMetaDataPayload(metadata)
                setStepper(2);    
            }).catch((error) => {
                console.log(error);
            });
        },
        onEvent:(eventName, metadata) => {
            console.log('onEvent eventName:', eventName)
            console.log('onEvent metadata:', metadata);
        },
        onExit:(error, metadata)=>{
            console.log('onExit error:', error)
            console.log('onExit metadata:', metadata);
        }
    });
    return (
        <>
            <div className='flex justify-center items-center gap-4 mx-auto mb-4 mt-4'>
                <span className="text-gray-500 text-sm font-semibold no-underline">Apply</span>
                <span className="text-gray-500 text-sm font-semibold no-underline">E-sign</span>
                <button className="btn rounded-full active-badge">
                    Funding Source &nbsp;
                    <div className={`badge badge-secondary text-[#fff] ${stepper === 1 ? 'hidden' : ''}`}>1</div>
                </button>
            </div>
            {stepper === 1 ?
                <Stepper1 openPlaid={open} />
                : <Stepper2 setStepper={setStepper} metadataPayload={metadataPayload}/>}
        </>
    )
}
export default FoundingSource