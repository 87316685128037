import React from "react";
import { Outlet } from "react-router-dom";

const MainLayout: React.FC = () => {
  return (
    <div className="max-w-7xl mx-auto px-2 py-10 sm:pt-28 sm:px-6 lg:px-2 bg-[url('./assets/images/decor.png')] bg-cover bg-bottom bg-no-repeat">
      <div className="min-h-screen items-center justify-center">
        <div className="max-w-xl mx-auto w-full">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
