import Ratee from "../../assets/images/ratee.png"
import { useNavigate } from "react-router"
const Rate = () => {
    const navigate = useNavigate()
    return (
        <div className="bg-white p-10 rounded-lg shadow-lg max-w-xl w-full">

            <center> <img src={Ratee} alt="rate" className="mx-auto mb-6" /> </center>
            <div className="mb-4 mx-auto text-center">
                <h1 className="font-semibold text-xl mb-2 mx-auto text-center">You're all set!</h1>

                <div className="text-center mx-auto mt-4 mb-3">
                    <span className="font-xl pb-10"> Rate your experience: </span>
                </div>

                <div className="ratingControl">
                    <input id="score100" className="ratingControl__radio" type="radio" name="rating" value="100" />
                    <label htmlFor="score100" className="ratingControl__star" title="Five Stars"></label>
                    <input id="score90" className="ratingControl__radio" type="radio" name="rating" value="90" />
                    <label htmlFor="score90" className="ratingControl__star" title="Four &amp; Half Stars"></label>
                    <input id="score80" className="ratingControl__radio" type="radio" name="rating" value="80" />
                    <label htmlFor="score80" className="ratingControl__star" title="Four Stars"></label>
                    <input id="score70" className="ratingControl__radio" type="radio" name="rating" value="70" />
                    <label htmlFor="score70" className="ratingControl__star" title="Three &amp; Half Stars"></label>
                    <input id="score60" className="ratingControl__radio" type="radio" name="rating" value="60" />
                    <label htmlFor="score60" className="ratingControl__star" title="Three Stars"></label>
                    <input id="score50" className="ratingControl__radio" type="radio" name="rating" value="50" />
                    <label htmlFor="score50" className="ratingControl__star" title="Two &amp; Half Stars"></label>
                    <input id="score40" className="ratingControl__radio" type="radio" name="rating" value="40" />
                    <label htmlFor="score40" className="ratingControl__star" title="Two Stars"></label>
                    <input id="score30" className="ratingControl__radio" type="radio" name="rating" value="30" />
                    <label htmlFor="score30" className="ratingControl__star" title="One &amp; Half Star"></label>
                    <input id="score20" className="ratingControl__radio" type="radio" name="rating" value="20" />
                    <label htmlFor="score20" className="ratingControl__star" title="One Star"></label>
                    <input id="score10" className="ratingControl__radio" type="radio" name="rating" value="10" />
                    <label htmlFor="score10" className="ratingControl__star" title="Half Star"></label>
                </div>
            </div>
            <textarea id="feedbackTextarea" className="hidden" placeholder="Please provide your feedback"></textarea>
            <form className="text-center">
                <button onClick={()=>navigate('/thankyou')} className="cursor-pointer bg-gray-700 hover:bg-gray-700 text-white font-bold py-4 px-8 rounded-full mx-auto" id="link-button">Finish</button>
            </form>
        </div>
    )
}
export default Rate