import { useState } from "react";

import FacialImage from "../../assets/images/facial-recognition.png";
import CameraIcon from "../../assets/images/camera.svg";

import Roboflow from "../../components/facial-recognition/Roboflow";

const FacialRecognition = () => {
  const [scanning, setScanning] = useState(false);

  return (
    <>
      {!scanning ? (
        <>
          <div className="min-h-screen flex items-center justify-center">
            <div className="bg-white p-10 rounded-2xl shadow-lg max-w-xl w-full">
              <div className="mx-auto text-center">
                <h1 className="font-bold text-2xl mb-1">Facial Recognition</h1>
                <p className="text-sm text-neutral-500">
                  Please put your face as tight as you can within the dotted lines.
                </p>
              </div>
              <img src={FacialImage} alt="rate" className="mx-auto my-8 rounded-xl" />
              <center>
                <button
                  onClick={() => setScanning(true)}
                  className="flex items-center rounded-lg py-2.5 pl-4 pr-5 bg-[#016F3C] font-medium text-sm text-white"
                >
                  <img src={CameraIcon} className="mr-2" />
                  Finish Authenticating...
                </button>
              </center>
            </div>
          </div>
        </>
      ) : (
        <Roboflow />
      )}
    </>
  );
};
export default FacialRecognition;
